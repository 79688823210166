<script lang="ts" setup></script>

<template>
  <footer>
    <div class="container">
      <div class="row mb-5">
        <div class="col-lg-8">
          <div class="row">
            <div class="col-lg-3 col-6">
              <div class="fs-20 fw-700 mb-3">حول كارترست</div>
              <ul>
                <li class="mb-2">
                  <NuxtLinkLocale class="fs-16 fw-500 text-black" to="/landing/about">
                    من نحن
                  </NuxtLinkLocale>
                </li>
                <li class="mb-2">
                  <a href="javascript:void(0)" class="fs-16 fw-500 text-black"> قصتنا </a>
                </li>
                <li class="mb-2">
                  <a href="javascript:void(0)" class="fs-16 fw-500 text-black">
                    شركائنا
                  </a>
                </li>
                <li class="mb-2">
                  <a href="javascript:void(0)" class="fs-16 fw-500 text-black">
                    عملائنا
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-lg-3 col-6">
              <div class="fs-20 fw-700 mb-3">&nbsp;</div>
              <ul>
                <li class="mb-2">
                  <a href="javascript:void(0)" class="fs-16 fw-500 text-black">
                    المستثمرين
                  </a>
                </li>
                <li class="mb-2">
                  <a href="javascript:void(0)" class="fs-16 fw-500 text-black">
                    سياسة الحيادية
                  </a>
                </li>
                <li class="mb-2">
                  <NuxtLinkLocale class="fs-16 fw-500 text-black" to="/privacy-policy">
                    سياسة الخصوصية
                  </NuxtLinkLocale>
                </li>
                <li class="mb-2">
                  <!-- <a href="javascript:void(0)" class="fs-16 fw-500 text-black">
                    الشروط و الأحكام
                  </a> -->
                  <NuxtLinkLocale
                    class="fs-16 fw-500 text-black"
                    to="/terms-and-condition"
                  >
                    الشروط و الأحكام</NuxtLinkLocale
                  >
                </li>
              </ul>
            </div>
            <div class="col-lg-3 col-6 mb-md-0 mb-4">
              <div class="fs-20 fw-700 mb-3">&nbsp;</div>
              <ul>
                <li class="mb-2">
                  <a href="javascript:void(0)" class="fs-16 fw-500 text-black">
                    ملف الشركة
                  </a>
                </li>
                <li class="mb-2">
                  <NuxtLinkLocale class="fs-16 fw-500 text-black" to="/landing/contact">
                    تواصل معنا
                  </NuxtLinkLocale>
                </li>
                <li class="mb-2">
                  <a href="javascript:void(0)" class="fs-16 fw-500 text-black">
                    التوظيف
                  </a>
                </li>
                <li class="mb-2">
                  <a href="javascript:void(0)" class="fs-16 fw-500 text-black">
                    الأسئلة المتكررة
                  </a>
                </li>
              </ul>
            </div>

            <div class="col-lg-3 col-6 mb-md-0 mb-4">
              <div class="fs-20 fw-700 mb-3">حمل التطبيق</div>
              <ul>
                <li class="mb-2">
                  <button type="button" class="bg-transparent border-0">
                    <img src="assets/images/app-blue.svg" height="41px" alt="" />
                  </button>
                </li>
                <li class="mb-4">
                  <button type="button" class="bg-transparent border-0">
                    <img src="assets/images/google-blue.svg" height="40px" alt="" />
                  </button>
                </li>
                <li class="mb-2">
                  <button type="button" class="bg-transparent border-0">
                    <img src="assets/images/number.svg" height="40px" alt="" />
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-md-0 mb-4">
          <div class="fs-20 fw-700 mb-3 mb-2">اشتراك في النشرة الإخبارية</div>
          <div class="input-footer position-relative">
            <input type="text" placeholder="البريد الإلكتروني" />
            <button type="button" class="main-btn h-auto px-4 py-3">
              <img src="assets/images/angle-left.svg" alt="" />
            </button>
          </div>
          <div class="d-flex align-items-center gap-2 mb-2">
            <div class="d-flex align-items-center gap-2">
              <img src="assets/images/star.svg" alt="" />
              <img src="assets/images/star.svg" alt="" />
              <img src="assets/images/star.svg" alt="" />
              <img src="assets/images/star.svg" alt="" />
              <img src="assets/images/star.svg" alt="" />
            </div>
            <div class="fs-20 fw-700">5.0</div>
          </div>
          <div class="fs-16 fw-500 mb-2">تم تقييم 1202 عميلاً</div>
          <div class="d-flex align-items-center mb-2 flex-wrap gap-3">
            <div class="fs-14 fw-500 text-grey">الاعتمادات</div>
            <img src="assets/images/foot-part1.png" alt="" />
            <img src="assets/images/foot-part2.png" alt="" />
            <img src="assets/images/saac.png" alt="" />
          </div>
          <div class="d-flex align-items-center mb-2 flex-wrap gap-3">
            <img src="assets/images/foot-part3.png" alt="" />
            <img src="assets/images/foot-part4.png" alt="" />
            <img src="assets/images/foot-part5.png" alt="" />
            <img src="assets/images/foot-part6.png" alt="" />
            <img src="assets/images/foot-part7.png" alt="" />
            <img src="assets/images/foot-part8.png" alt="" />
          </div>
        </div>
      </div>
      <div class="sub-footer pt-3">
        <div
          class="d-flex align-items-center flex-wrap justify-content-md-between gap-md-0 gap-4 justify-content-center"
        >
          <img src="assets/images/logo.png" alt="" />

          <div class="d-flex align-items-center gap-3">
            <div class="fs-16 fw-500 text-theme">حسابات التواصل الإجتماعي</div>
            <div class="d-flex align-items-center gap-2">
              <a target="_blank" href="https://www.youtube.com/@cartrust5834"
                ><img src="assets/images/youtube.svg" alt=""
              /></a>
              <a target="_blank" href="https://www.tiktok.com/@cartrustksa"
                ><img src="assets/images/tiktok.svg" alt=""
              /></a>
              <a target="_blank" href="https://twitter.com/CarTrustksa"
                ><img src="assets/images/twitter.svg" alt=""
              /></a>
              <a target="_blank" href="https://www.instagram.com/cartrustksa"
                ><img src="assets/images/instagram.svg" alt=""
              /></a>
              <a
                target="_blank"
                href="https://www.facebook.com/profile.php?id=61556080312974"
                ><img src="assets/images/facebook.svg" alt=""
              /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="pt-3">
        <div class="d-flex align-items-center flex-wrap justify-content-center">
          <div class="d-flex align-items-center gap-3 order-lg-1 order-2">
            <div class="d-flex align-items-center gap-2">
              <div class="fs-16 fw-500 text-theme">
                كل الحقوق محفوظة لدى شركة ثقة المركبة للتجارة @ 2024
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="css" scoped>
footer {
  background: #f6f6f6;
  padding: 70px 0;
  margin-top: 50px;
}
footer {
  padding: 40px 0;
}

.input-footer input {
  background: #e9e9e9bf;
  border: none;
  outline: none;
  border-radius: 15px;
  width: 100%;
  height: 64px;
  padding: 0 15px;
}
.input-footer .main-btn {
  position: absolute;
  top: 8px;
  left: 15px;
}
</style>
